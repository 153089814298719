import { IEventBus } from "../idn-media-framework-abstraction/IEventBus";
import { IEventBusEvent } from "../idn-media-framework-abstraction/IEventBusEvent";
import { Hub } from 'aws-amplify';
import { HubCapsule, HubCallback } from '@aws-amplify/core';
import { EventBusEventHandlerDelegate } from "../idn-media-framework-abstraction/EventBusEventHandlerDelegate";
import { IEventBusSubcription } from "../idn-media-framework-abstraction/IEventBusSubcription";
import { EventBusSubscriptionUsingAmplifyHub } from "./EventBusSubscriptionUsingAmplifyHub";
import { toHubPayload } from "./toHubPayload";
import { toEventBusEvent } from "./toEventBusEvent";
import { ILogger } from "../idn-media-framework-abstraction/ILogger";
import { inject } from "tsyringe";

export class EventBusUsingAmplifyHub implements IEventBus {

  constructor(private _logger: ILogger){

  }

  async Subscribe<T>(topicName: string, handler: EventBusEventHandlerDelegate<T>): Promise<IEventBusSubcription> {
    const eventHandler: HubCallback = (capsule: HubCapsule) => {
      handler(toEventBusEvent(capsule))
    };
    Hub.listen(topicName, eventHandler);

    return new EventBusSubscriptionUsingAmplifyHub(topicName, eventHandler, this._logger);
  }

  Unsubscribe(subscription: IEventBusSubcription): Promise<void> {
    return subscription.Unsubscribe();
  }

  async CreateTopic(topicName: string): Promise<void> {
    //Do nothing since in Hub we don't need to create a topic
  }

  async Dispatch<T>(topicName: string, theEvent: IEventBusEvent<T>): Promise<void> {
    this._logger.debug(`Dispatching in topic ${topicName} for event: ${theEvent.eventName}`)
    Hub.dispatch(topicName, toHubPayload<T>(theEvent))
  }
}


