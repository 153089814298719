import { IInitializer } from "../idn-media-framework-abstraction/IInitializer";
import { ILogger } from "../idn-media-framework-abstraction/ILogger";
import { LoggingLevels } from "../idn-media-framework-abstraction/LoggingLevels";


export class SequentialInitializer implements IInitializer {

  constructor(private _logger: ILogger, private _initializers: IInitializer[]){
    this._logger = this._logger.Create('SequentialInitializer', LoggingLevels.DEBUG);
  }

  async Initialize(): Promise<void> {
    const initializers: IInitializer[] = this._initializers;
    
    for(let i = 0; i < initializers.length; i++){
      this._logger.debug('I:', i);
      const initializer = initializers[i];
      await initializer.Initialize();
    }    
  }


}