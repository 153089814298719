import { DependencyContainer } from "tsyringe";
import { IGenericDIContainer } from "../idn-media-framework-abstraction/IGenericDIContainer";

export class GenericContainerUsingDependencyContainer implements IGenericDIContainer {

  constructor(private _diContainer: DependencyContainer){

  }

  Resolve<T>(name: string): T {
    return this._diContainer.resolve<T>(name);
  }

}