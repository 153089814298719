import { Amplify } from 'aws-amplify';
import { IInitializer } from "../idn-media-framework-abstraction/IInitializer";
import { ILogger } from '../idn-media-framework-abstraction/ILogger';

export class AmplifyInitializer implements IInitializer {
  constructor(private _awsConfig: any, private _logger: ILogger){

  }
  async Initialize(): Promise<void> {
    console.log("adsad", this._logger)
    this._logger.debug('Calling Amplify.configure()...');
    
    let queryStringParams = new URLSearchParams(window.location.search);
    let clientId = queryStringParams.get("client_id");
    if (clientId) {
      // We save the client ID, our Amplify auth will be based on that one
      localStorage.setItem("client-id", clientId);
    } else {
      // If there is no client-Id in query, we set back the last one used for login
      // it may be undefined if we never logged in
      clientId = localStorage.getItem("client-id");
    }
    if (clientId) {
      // We configure the Amplify Auth component in the context of using a client website client-id
      // if no clientId is found (direct login not from a client) the web client id of the broker will be used as default
      this._awsConfig.aws_user_pools_web_client_id = clientId;
    } else if (process.env.NEXT_PUBLIC_userPoolWebClientId) {
      this._awsConfig.aws_user_pools_web_client_id = process.env.NEXT_PUBLIC_userPoolWebClientId
    }

    var url = window.location.href.split('/');
    var domain = url[0] + '//' + url[2];

    if (!domain.endsWith('/')) {
      domain = domain + '/';
    }
    
    this._awsConfig.authenticationFlowType = "USER_PASSWORD_AUTH";
    this._awsConfig.oauth.redirectSignIn = domain;
    this._awsConfig.oauth.redirectSignOut = domain + "signout/";

    Amplify.configure(this._awsConfig);
    
    this._logger.debug('Amplify.configure() [DONE]');
  }

}