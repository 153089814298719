import { IEventBusEvent } from "../idn-media-framework-abstraction/IEventBusEvent";
import { HubPayload } from '@aws-amplify/core';

export function toHubPayload<T>(theEvent: IEventBusEvent<T>): import("@aws-amplify/core").HubPayload {
  return ({
    event: theEvent.eventName,
    data: theEvent.data,
    message: theEvent.message
  } as HubPayload);
}
