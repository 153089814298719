import { IEventBusEvent } from "../idn-media-framework-abstraction/IEventBusEvent";
import { HubCapsule } from '@aws-amplify/core';

export function toEventBusEvent<T>(capsule: HubCapsule): IEventBusEvent<T> {
  return ({
    data: capsule.payload.data || {},
    eventName: capsule.payload.event,
    message: capsule.payload.message
  } as IEventBusEvent<T>);
}
