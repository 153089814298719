import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    lato: 'Lato, sans-serif',
    body: 'Lato, sans-serif',
  },
});

export default theme;
