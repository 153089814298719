const awscognito = {
  "aws_project_region": "ap-southeast-1",
  "aws_cognito_identity_pool_id": `${process.env.NEXT_PUBLIC_IDENTITY_POOL_ID}`,
  "aws_cognito_region": "ap-southeast-1",
  "aws_user_pools_id": `${process.env.NEXT_PUBLIC_SSO_USERPOOL_ID}`,
  "aws_user_pools_web_client_id": `${process.env.NEXT_PUBLIC_SSO_CLIENT_ID}`,
  "oauth": {
    "domain": `${process.env.NEXT_PUBLIC_SSO_OAUTH_DOMAIN}`,
    "scope": [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    "redirectSignIn": `${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
    "redirectSignOut": `${process.env.NEXT_PUBLIC_DOMAIN_NAME}logout`,
    "responseType": "code"
  },
  "federationTarget": "COGNITO_USER_POOLS",
  "aws_cognito_username_attributes": [],
  "aws_cognito_social_providers": [
    "FACEBOOK",
    "GOOGLE",
    "APPLE"
  ],
  "aws_cognito_signup_attributes": [
    "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL",
    "PHONE_NUMBER"
  ],
  "aws_cloud_logic_custom": [
    {
      "name": "oauthservices",
      "endpoint": `${process.env.NEXT_PUBLIC_DOMAIN_NAME}`,
      "region": "ap-southeast-1"
    }
  ]
};
    export default awscognito;
    