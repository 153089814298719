import { IInitializer } from "../idn-media-framework-abstraction/IInitializer";
import { IKeyBasedStorage } from "../idn-media-framework-abstraction/IKeyBasedStorage";

export class KeyBasedStorageInitializer implements IInitializer {

  constructor(private _keyBasedStorageService: IKeyBasedStorage){

  }

  async Initialize(): Promise<void> {
    await this._keyBasedStorageService.Initialise();
  }

}