export const CustomErrors = {
  DEFAULT_MSG: 'Maaf, sepertinya ada kesalahan di sistem kami, coba lagi.',
  USER_NOT_EXIST: 'User does not exist.',
  USER_NOT_EXIST_IDNACCOUNT: 'UserMigration failed with error user tidak ditemukan.',
  USER_ALREADY_DELETED: 'UserMigration failed with error user already deleted.',
  INCORRECT_USERNAME_PASSWORD: 'Incorrect username or password.',
  INCORRECT_PASSWORD_IDNACCOUNT: 'UserMigration failed with error password tidak sama.',
  PASSWORD_ATTEMPT_LIMIT_EXCEEDED: 'Password attempts exceeded',
  FORGOT_PASSWORD_USER_NOT_FOUND: 'Username/client id combination not found.',
  FORGOT_PASSWORD_USER_NOT_FOUND_IDNACCOUNT: 'UserMigration failed with error user tidak ditemukan.',
  INVALID_VERIFICATION_CODE: 'Invalid verification code provided, please try again.',
  PASSWORD_LENGTH: 'Password does not conform to policy: Password not long enough',
  PASSWORD_UPPERCASE: 'Password does not conform to policy: Password must have uppercase characters',
  PASSWORD_LOWERCASE: 'Password does not conform to policy: Password must have lowercase characters',
  PASSWORD_NUMERIC: 'Password does not conform to policy: Password must have numeric characters',
  PASSWORD_SYMBOL: 'Password does not conform to policy: Password must have symbol characters',
  PASSWORD_LENGTH_REGISTER: 'Password did not conform with policy: Password not long enough',
  PASSWORD_UPPERCASE_REGISTER: 'Password did not conform with policy: Password must have uppercase characters',
  PASSWORD_LOWERCASE_REGISTER: 'Password did not conform with policy: Password must have lowercase characters',
  PASSWORD_NUMERIC_REGISTER: 'Password did not conform with policy: Password must have numeric characters',
  PASSWORD_SYMBOL_REGISTER: 'Password did not conform with policy: Password must have symbol characters',
  ATTEMPT_LIMIT_EXCEEDED: 'Attempt limit exceeded, please try after some time.',
  EMAIL_ALREADY_EXIST_REGISTER: 'An account with the given email already exists.',
  PHONE_NUMBER_ALREADY_EXIST_REGISTER: 'An account with the given phone_number already exists.',
  USER_ALREADY_EXIST_REGISTER_IDNACCOUNT: 'PreSignUp failed with error user already exists.',
  USER_ALREADY_DELETED_REGISTER_IDNACCOUNT: 'PreSignUp failed with error user already deleted.',
  INVALID_USERNAME_REGISTER: 'Username should be either an email or a phone number.',
  NAME_ATTRIBUTE_REQUIRED: 'PreSignUp failed with error name is required.',
  EMAIL_ATTRIBUTE_REQUIRED: 'PreSignUp failed with error email / phone number is required.',
  PHONE_NUMBER_ATTRIBUTE_REQUIRED: 'PreSignUp failed with error email / phone number is required.',
  CUSTOM_AUTH_LAMBDA_NOT_CONFIGURED: 'Custom auth lambda trigger is not configured for the user pool.',
  DISABLE_EMAIL_PERMISSION_FACEBOOK: 'attributes required: [email]',
  DISABLE_EMAIL_PERMISSION_FACEBOOK_PRESIGNUP_TRIGGER: 'PreSignUp failed with error email is required. please enabled permission email or fill your email on social media platform.',
  ACCESS_DENIED_FACEBOOK: 'Permissions error; error=access_denied; error_code=200; error_reason=user_denied',
  CANCELLED_AUTHORIZE_APPLE: 'Error response from Identity Provider; error=user_cancelled_authorize',
  INVALID_EMAIL_FORMAT: 'Invalid email address format.',
  INVALID_PHONE_NUMBER_FORMAT: 'Invalid phone number format.',
  USER_ALREADY_DELETED_SIGNIN_IDNACCOUNT: 'PreAuthentication failed with error user already deleted.',
  SOCMED_SIGNIN_RESET_REQUIRED: `Kamu belum punya password. Silakan buat password akun dulu di "Atur ulang password".`,
  RECAPTCHA_BOT_DETECTED: `PreSignUp failed with error bot detected from recaptcha.`
};
