import { Auth, Hub } from "aws-amplify";
import { IAuthenticationService } from "../idn-media-framework-abstraction/IAuthenticationService";
import { IEventBus } from "../idn-media-framework-abstraction/IEventBus";
import { ILogger } from "../idn-media-framework-abstraction/ILogger";
import { LoggingLevels } from "../idn-media-framework-abstraction/LoggingLevels";
import { CognitoUser } from "@aws-amplify/auth";
export class AuthenticationServiceUsingAmplifyAuth implements IAuthenticationService {

  private _hubSubscription = false;

  protected getIsAutenticated(user: CognitoUser): boolean{
    if (
      !user ||
      !user.getSignInUserSession() ||
      !user.getSignInUserSession()!.isValid() // isValid() also verified the Token Signature
    ) {
      return false
    }
    return true;
  }


  constructor(private _logger: ILogger, private _eventBus: IEventBus){
    this._logger = this._logger.Create('AuthenticationServiceUsingAmplifyAuth', LoggingLevels.DEBUG);

    if(!this._hubSubscription){
      this._logger.debug("Subscribing to Amplify Auth's events...")
      this._eventBus.CreateTopic('IAuthenticationService')
      Hub.listen("auth", this.handleAuth);
      this._hubSubscription = true; 
      this._logger.debug("Subscribing to Amplify Auth's events...[DONE]");      
    }    

  }
  CleanUp(): Promise<void> {
    if(this._hubSubscription){
      Hub.remove("auth", this.handleAuth);
      this._hubSubscription = false;
    }
    return Promise.resolve();
  }

  async CheckLoggedIn(): Promise<any> {
    try {
      const getUSerInfo = await Auth.currentUserInfo();
      if (Object.keys(getUSerInfo).length === 0) {
        this._eventBus.Dispatch('IAuthenticationService', {eventName: 'NOT_LOGGED_IN', data: null, message: ''})
        return;
      }

      const user: CognitoUser = await Auth.currentAuthenticatedUser();  

      if(this.getIsAutenticated(user)){
        this._eventBus.Dispatch('IAuthenticationService', {eventName: 'signIn', data: user, message: ''})
      }else{
        this._eventBus.Dispatch('IAuthenticationService', {eventName: 'NOT_LOGGED_IN', data: null, message: ''})
      }  
    } catch (error) {
      this._eventBus.Dispatch('IAuthenticationService', {eventName: 'NOT_LOGGED_IN', data: null, message: ''})
      this._logger.error(error);
    }
    

  }


  handleAuth ({ payload } : {payload: any}) {
    console.log('handleAuth, payload:', payload);

    if(!this._eventBus){
      Hub.remove("auth", this.handleAuth);
      return;
    }

    switch (payload.event) {
      case 'parsingCallbackUrl':
        if (payload.data.url) {          
          let urls = payload.data.url.split("?")
          if (urls[0] === '') {
            // InAppBrowser.close();
            // setIsLoggedIn(true);
          } else if (urls[0] === '') {
            // InAppBrowser.close();
          }
        }
        break;
      case 'signIn':
        this._eventBus.Dispatch('IAuthenticationService', {eventName: 'signIn', data: payload, message: ''})
        break;
      case 'signOut':
        // InAppBrowser.close();   
        // setIsLoggedIn(false);     
        this._eventBus.Dispatch('IAuthenticationService', {eventName: 'signOut', data: payload, message: ''})
        break
    }
  }    


  async Logout(): Promise<any> {
    this._logger.debug('Logout called, calling Auth.signOut()');

    // handle global signout
    try {
      await Auth.signOut({
        global: true,
      });
    } catch (error: any) {
      // when other client has been logged out / access token has ben revoked, do regular signOut
      if (error.code === 'NotAuthorizedException') {
        await Auth.signOut();
      }
    }
  }
  StartSingleSignOn(): Promise<any> {
    this._logger.debug('StartSingleSignOn called, calling Auth.federatedSignIn()');
    return Auth.federatedSignIn();
  }

}