import { IAuthenticationService } from "../idn-media-framework-abstraction/IAuthenticationService";
import { IInitializer } from "../idn-media-framework-abstraction/IInitializer";
import { ILogger } from "../idn-media-framework-abstraction/ILogger";
import { LoggingLevels } from "../idn-media-framework-abstraction/LoggingLevels";
import { DIContainer } from "../idn-media-framework-tsyringe/DIContainer";

export class AuthenticationServiceUsingAmplifyAuthInitializer implements IInitializer {
  constructor(private _logger: ILogger){
    this._logger = this._logger.Create('AuthenticationServiceUsingAmplifyAuthInitializer', LoggingLevels.DEBUG);

    
  }
  async Initialize(): Promise<void> {
    this._logger.debug('Initializing IAuthenticationService...');
    const service = DIContainer.Resolve<IAuthenticationService>('IAuthenticationService');
    this._logger.debug('Initializing IAuthenticationService...[DONE]');
  }

}