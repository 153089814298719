var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"M0SJwXT1eG25DnIe5h01a"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs'
import { CaptureConsole as CaptureConsoleIntegration, HttpClient as HttpClientIntegration } from "@sentry/integrations";

Sentry.init({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  environment: process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT,
  integrations: [
  new CaptureConsoleIntegration(
    {
      // array of methods that should be captured
      // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
      levels: ['error'],
    }
  ),
  new HttpClientIntegration({
    // This array can contain tuples of `[begin, end]` (both inclusive),
    // single status codes, or a combination of both.
    // default: [[500, 599]]
    failedRequestStatusCodes: [[400, 499], [500, 599]],

    // This array can contain Regexes, strings, or a combination of both.
    // default: [/.*/],
    
  }),],
  sendDefaultPii: true,
});