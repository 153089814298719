import { ILogger } from "../packages/idn-media-framework-abstraction/ILogger";
import { LoggerUsingAmplifyLogger } from "../packages/idn-media-framework-amplify/LoggerUsingAmplifyLogger";
import { DIContainer } from "../packages/idn-media-framework-tsyringe/DIContainer";
import { AmazonPersonalizeProvider, Amplify } from 'aws-amplify';
import { AmplifyInitializer } from '../packages/idn-media-framework-amplify/AmplifyInitializer';
import awsExports from '../src/aws-exports';
import { IGenericDIContainer } from '../packages/idn-media-framework-abstraction/IGenericDIContainer';
import { EventBusUsingAmplifyHub } from '../packages/idn-media-framework-amplify/EventBusUsingAmplifyHub';
import { IEventBus } from '../packages/idn-media-framework-abstraction/IEventBus';
import { LoggingLevels } from '../packages/idn-media-framework-abstraction/LoggingLevels';
import { AmplifyAuthInitializer } from '../packages/idn-media-framework-amplify/AmplifyAuthInitializer';
import { IAuthenticationService } from '../packages/idn-media-framework-abstraction/IAuthenticationService';
import { AuthenticationServiceUsingAmplifyAuth } from '../packages/idn-media-framework-amplify/AuthenticationServiceUsingAmplifyAuth';
import { KeyBasedStorageInitializer } from '../packages/idn-media-framework/KeyBasedStorageInitializer';
import { IKeyBasedStorage } from '../packages/idn-media-framework-abstraction/IKeyBasedStorage';
import { KeyBasedStorageUsingCrossStorage } from '../packages/idn-media-framework-cross-storage/KeyBasedStorageUsingCrossStorage';
import { Container } from '@chakra-ui/react';
import { SequentialInitializer } from '../packages/idn-media-framework/SequentialInitializer';
import { IInitializer } from '../packages/idn-media-framework-abstraction/IInitializer';
import { AuthenticationServiceUsingAmplifyAuthInitializer } from "../packages/idn-media-framework-amplify/AuthenticationServiceUsingAmplifyAuthInitializer";


export class CompositionRoot {

  private static _eventBus: IEventBus | null = null;
  private static _authService: IAuthenticationService | null = null;
  private static _keyBasedStorageService: IKeyBasedStorage | null = null;

  private static _initialising = false;
  private static _singletons:any = {};

  private static async ComposeApplication(){    
    DIContainer.RegisterAsService('ILogger', LoggerUsingAmplifyLogger);
    DIContainer.RegisterAsValue('AWSConfig', awsExports);
    
    DIContainer.RegisterWithFactory('AmplifyInitializer', (container: IGenericDIContainer) => {
      const awsConfig = container.Resolve('AWSConfig');
      const logger = container.Resolve<ILogger>('ILogger')
      return new AmplifyInitializer(awsConfig, logger);
    });

    DIContainer.RegisterWithFactory('AmplifyAuthInitializer', (container: IGenericDIContainer) => {
      const eventBus = container.Resolve<IEventBus>('IEventBus');
      const logger = container.Resolve<ILogger>('ILogger')
      return new AmplifyAuthInitializer(logger, eventBus);
    });

    DIContainer.RegisterWithFactory('Initializers', (container: IGenericDIContainer) => {
      return ([
        // container.Resolve('KeyBasedStorageInitializer'),        
        container.Resolve('AmplifyInitializer'),
        new AuthenticationServiceUsingAmplifyAuthInitializer(container.Resolve<ILogger>('ILogger')),
        // container.Resolve('AmplifyAuthInitializer'),
        
      ])
    })

    DIContainer.RegisterWithFactory('ApplicationInitializer', (container: IGenericDIContainer)=> {
      if(!this._singletons['ApplicationInitializer']){
        this._singletons['ApplicationInitializer'] = new SequentialInitializer(
          container.Resolve<ILogger>('ILogger'),
          container.Resolve<IInitializer[]>('Initializers'),          
        )
      }

      return this._singletons['ApplicationInitializer'];
    })

    DIContainer.RegisterWithFactory('IAuthenticationService', (container: IGenericDIContainer)=> {
      if(!this._singletons['IAuthenticationService']){
        this._singletons['IAuthenticationService'] = new AuthenticationServiceUsingAmplifyAuth(
          container.Resolve<ILogger>('ILogger'),
          container.Resolve<IEventBus>('IEventBus'),
        );        
      }
      return this._singletons['IAuthenticationService'];
    });

    DIContainer.RegisterWithFactory('KeyBasedStorageInitializer', (container: IGenericDIContainer) => {
      const keyBasedStorageService = container.Resolve<IKeyBasedStorage>('IKeyBasedStorage');
      const logger = container.Resolve<ILogger>('ILogger')
      return new KeyBasedStorageInitializer(keyBasedStorageService);
    });

    DIContainer.RegisterWithFactory('IKeyBasedStorage', (container: IGenericDIContainer) => {
      if(!CompositionRoot._keyBasedStorageService){
        const logger = container.Resolve<ILogger>('ILogger')
        CompositionRoot._keyBasedStorageService = new KeyBasedStorageUsingCrossStorage(logger);
      }
      return CompositionRoot._keyBasedStorageService;
    });


    DIContainer.RegisterWithFactory('IEventBus', (container: IGenericDIContainer) => {
      if(!CompositionRoot._eventBus){
        this._eventBus = new EventBusUsingAmplifyHub(container.Resolve<ILogger>('ILogger').Create('IEventBus', LoggingLevels.DEBUG));
      }
      return this._eventBus;
    });

  }

  static async InitializeApplication(){
    if(CompositionRoot._initialising){
      return;
    }
    CompositionRoot._initialising = true;
    await CompositionRoot.ComposeApplication();

    Amplify.Logger.LOG_LEVEL = (process.env.NODE_ENV === 'production') ? "ERROR" : "DEBUG";
    await DIContainer.Resolve<IInitializer>('ApplicationInitializer').Initialize();
  }

}