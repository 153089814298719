import { AuthErrorStrings } from '@aws-amplify/auth';
import { CustomErrors } from '@/constants/customErrorStrings';

export const customErrorMessages = {
  [AuthErrorStrings.EMPTY_USERNAME]: `Email / Nomor HP harus diisi.`,
  [AuthErrorStrings.EMPTY_EMAIL]: `Email harus diisi.`,
  [AuthErrorStrings.EMPTY_PHONE]: `Nomor HP harus diisi.`,
  [AuthErrorStrings.INVALID_USERNAME]: `Pastikan format email atau nomor telepon kamu benar.`,
  [AuthErrorStrings.EMPTY_PASSWORD]: `Password harus diisi.`,
  [AuthErrorStrings.EMPTY_CODE]: `Kode Verifikasi harus diisi.`,
  [AuthErrorStrings.SIGN_UP_ERROR]: `Maaf, sepertinya ada kesalahan di sistem kami, coba lagi.`,
  [AuthErrorStrings.NETWORK_ERROR]: `Sepertinya ada kesalahan di jaringan, coba lagi.`,
  [CustomErrors.CUSTOM_AUTH_LAMBDA_NOT_CONFIGURED]: `Server kami tidak bisa memproses dengan request yang kamu kirimkan.`,
  [CustomErrors.USER_NOT_EXIST]: `Email / Nomor HP kamu belum terdaftar. Silakan daftar dulu atau masuk dengan media sosial.`,
  [CustomErrors.USER_NOT_EXIST_IDNACCOUNT]: `Email / Nomor HP kamu belum terdaftar. Silakan daftar dulu atau masuk dengan media sosial.`,
  [CustomErrors.USER_ALREADY_DELETED]: `Akun ini sudah terhapus. Silahkan daftar lagi atau masuk dengan akun lain, ya.`,
  [CustomErrors.INCORRECT_USERNAME_PASSWORD]: `Password yang kamu masukkan salah.`,
  [CustomErrors.INCORRECT_PASSWORD_IDNACCOUNT]: `Password yang kamu masukkan salah.`,
  [CustomErrors.PASSWORD_ATTEMPT_LIMIT_EXCEEDED]: `Kamu sudah melebihi batas salah password. Coba beberapa saat lagi atau atur ulang password, ya.`,
  [CustomErrors.FORGOT_PASSWORD_USER_NOT_FOUND_IDNACCOUNT]: `Email / Nomor HP ini belum terdaftar. Masukkan email / Nomor HP yang sudah didaftarkan, ya.`,
  [CustomErrors.FORGOT_PASSWORD_USER_NOT_FOUND]: `Email / Nomor HP ini belum terdaftar. Masukkan email / Nomor HP yang sudah didaftarkan, ya.`,
  [CustomErrors.INVALID_VERIFICATION_CODE]: `Oops! Kode yang kamu masukkan salah.`,
  [CustomErrors.PASSWORD_LENGTH]: `Password kamu kurang dari 8 karakter.`,
  [CustomErrors.PASSWORD_UPPERCASE]: `Password harus terdiri dari huruf besar dan kecil.`,
  [CustomErrors.PASSWORD_LOWERCASE]: `Password harus terdiri dari huruf besar dan kecil.`,
  [CustomErrors.PASSWORD_NUMERIC]: `Password wajib ada 1 simbol !@#$%^&*() dan 1 angka.`,
  [CustomErrors.PASSWORD_SYMBOL]: `Password wajib ada 1 simbol !@#$%^&*() dan 1 angka.`,
  [CustomErrors.ATTEMPT_LIMIT_EXCEEDED]: `Kamu sudah melampaui batas percobaan. Silakan tunggu beberapa saat lagi, ya.`,
  [CustomErrors.EMAIL_ALREADY_EXIST_REGISTER]: `Sepertinya email kamu sudah terdaftar.`,
  [CustomErrors.PHONE_NUMBER_ALREADY_EXIST_REGISTER]: `Sepertinya nomor HP kamu sudah terdaftar.`,
  [CustomErrors.USER_ALREADY_DELETED_REGISTER_IDNACCOUNT]: `Akun ini sudah terhapus. Silahkan daftar lagi atau masuk dengan akun lain, ya.`,
  [CustomErrors.INVALID_USERNAME_REGISTER]: `Format email atau nomor telepon kamu salah.`,
  [CustomErrors.PASSWORD_LENGTH_REGISTER]: `Password kamu kurang dari 8 karakter.`,
  [CustomErrors.PASSWORD_UPPERCASE_REGISTER]: `Password harus terdiri dari huruf besar dan kecil.`,
  [CustomErrors.PASSWORD_LOWERCASE_REGISTER]: `Password harus terdiri dari huruf besar dan kecil.`,
  [CustomErrors.PASSWORD_NUMERIC_REGISTER]: `Password wajib ada 1 simbol !@#$%^&*() dan 1 angka.`,
  [CustomErrors.PASSWORD_SYMBOL_REGISTER]: `Password wajib ada 1 simbol !@#$%^&*() dan 1 angka.`,
  [CustomErrors.DISABLE_EMAIL_PERMISSION_FACEBOOK]: `Mohon izinkan akses email untuk masuk ke IDN.`,
  [CustomErrors.DISABLE_EMAIL_PERMISSION_FACEBOOK_PRESIGNUP_TRIGGER]: `Mohon izinkan akses email untuk masuk ke IDN.`,
  [CustomErrors.NAME_ATTRIBUTE_REQUIRED]: `Nama harus diisi.`,
  [CustomErrors.EMAIL_ATTRIBUTE_REQUIRED]: `Email / Nomor HP harus diisi.`,
  [CustomErrors.PHONE_NUMBER_ATTRIBUTE_REQUIRED]: `Email / Nomor HP harus diisi.`,
  [CustomErrors.INVALID_EMAIL_FORMAT]: `Alamat email tidak valid. Coba periksa lagi, ya.`,
  [CustomErrors.USER_ALREADY_DELETED_SIGNIN_IDNACCOUNT]: `Akun ini sudah terhapus. Silahkan daftar lagi atau masuk dengan akun lain, ya.`,
  [CustomErrors.INVALID_PHONE_NUMBER_FORMAT]: `Nomor HP tidak valid. Coba periksa lagi, ya.`,
  [CustomErrors.RECAPTCHA_BOT_DETECTED]: `Sepertinya Anda terdeteksi sebagai bot.`,
  UserNotFoundExceptionEmail: `Email kamu belum terdaftar. Silakan daftar dulu atau masuk dengan media sosial.`,
  UserNotFoundExceptionPhoneNumber: `Maaf, nomor hp kamu belum terdaftar.`,
  UserNotFoundExceptionForgotEmail: `Email ini belum terdaftar. Masukkan email yang sudah didaftarkan, ya.`,
  UserNotFoundExceptionForgotPhoneNumber: `Nomor HP ini belum terdaftar. Masukkan Nomor HP yang sudah didaftarkan, ya.`,
  LimitExceededForgotPasswordException: `Kamu sudah berkali-kali memasukkan email / Nomor HP. Silakan tunggu beberapa saat lagi, ya.`,
  LimitExceededInvalidCodeException: `Kamu sudah berulang kali salah kode. Coba beberapa saat lagi.`,
  LimitExceededResendCodeException: `Kamu sudah melewati batas kirim kode. Coba beberapa saat lagi.`
};
