import { IEventBusEvent } from "../IEventBusEvent";

export interface IAuthenticationEventPayload {
  user: any;
  isAuthenticated: boolean;
}

export class AuthenticationEvent<T> implements IEventBusEvent<IAuthenticationEventPayload> {

  constructor(_user: T, _isAuthenticated: boolean, message: string){
    this.eventName = 'AuthenticationEvent';
    this.data = {
      isAuthenticated: _isAuthenticated,
      user: _user
    }

    this.message = message;
  }
  eventName: string;
  data: IAuthenticationEventPayload;
  message: string;
}