import { IKeyBasedStorage } from '../idn-media-framework-abstraction/IKeyBasedStorage';

import { CrossStorageClient } from 'cross-storage';
import { ILogger } from '../idn-media-framework-abstraction/ILogger';
import { LoggingLevels } from '../idn-media-framework-abstraction/LoggingLevels';

export class KeyBasedStorageUsingCrossStorage implements IKeyBasedStorage {

  private _storage: CrossStorageClient;
  private _memoryStorage: any = {};

  constructor(private _logger: ILogger) {
    this._logger = this._logger.Create('KeyBasedStorageUsingCrossStorage', LoggingLevels.DEBUG);

    this._storage = new CrossStorageClient('https://ssoproxy.d3lxdza9zvj5kf.amplifyapp.com', {frameId: 'IDN_AUTH'});
  }

  async Initialise(): Promise<void> {
    this._logger.debug('Connecting to HUB...')
    await this._storage.onConnect();
    this._logger.debug('Connecting to HUB...[DONE]');

    const itemKeys = await this._storage.getKeys() || [];
    this._logger.debug('HUB Keys:', itemKeys);

    for(let i = 0; i < itemKeys.length; i++){
      const itemKey = itemKeys[i];
      const itemValue = await this._storage.get(itemKey);
      this._memoryStorage[itemKey] = itemValue;
      this._logger.debug(`Assigning memory storage ${itemKey}:${itemValue}`);
    }
  }
  async sync(): Promise<void> {
    
  }
  async setItemAsync(key: string, value: string): Promise<string> {
    await this._storage.set(key, value);
    return value;
  }
  getItemAsync(key: string): Promise<string> {
    return this._storage.get(key);
  }
  removeItemAsync(key: string): Promise<void> {
    return this._storage.del(key);
  }
  clearAsync(): Promise<void> {
    return this._storage.clear();
  }

  syncPromise: any;
  
  setItem(key: string, value: string): string {
    this._memoryStorage[key] = value;
    this.setItemAsync(key, value);
    return value;
  }
  getItem(key: string): string {
    return this._memoryStorage[key];
  }
  removeItem(key: string): void {
    delete this._memoryStorage[key];
    this.removeItemAsync(key);
  }
  clear(): void {
    this._memoryStorage = {};
    this.clearAsync();
  }

}