import { CognitoUser } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";
import { AuthenticationEvent } from "../idn-media-framework-abstraction/events/AuthenticationEvent";
import { IEventBus } from "../idn-media-framework-abstraction/IEventBus";
import { IInitializer } from "../idn-media-framework-abstraction/IInitializer";
import { IKeyBasedStorage } from "../idn-media-framework-abstraction/IKeyBasedStorage";
import { ILogger } from "../idn-media-framework-abstraction/ILogger";
import { DIContainer } from "../idn-media-framework-tsyringe/DIContainer";
import { Topics } from "./Topics";

export class AmplifyAuthInitializer implements IInitializer {

  constructor(private _logger: ILogger, private _eventBus: IEventBus){

  }

  protected getIsAutenticated(user: CognitoUser): boolean{
    if (
      !user ||
      !user.getSignInUserSession() ||
      !user.getSignInUserSession()!.isValid() // isValid() also verified the Token Signature
    ) {
      return false
    }
    return true;
  }

  async Initialize(): Promise<void> {
    this._logger.debug('Initializing Amplify Auth....');

    var url = window.location.href.split("/");
    var domain = url[0] + "//" + url[2]

    if (!domain.endsWith('/')) {
      domain = domain + '/';
    }
    
    const idpDomain = process.env.NEXT_PUBLIC_idpDomain;
    const userPoolId = process.env.NEXT_PUBLIC_userPoolId;
    const userPoolWebClientId = process.env.NEXT_PUBLIC_userPoolWebClientId;

    Auth.configure({
      userPoolId: userPoolId,
      userPoolWebClientId: userPoolWebClientId,
      oauth: {
        domain: idpDomain,
        scope: ["email", "profile", "openid"],
        redirectSignIn: domain,
        redirectSignOut: domain + "signout/",
        responseType: "code",
      },
      authenticationFlowType: "USER_PASSWORD_AUTH",
    })

    this._eventBus.CreateTopic(Topics.IDN_AUTHENTICATION);    

    
      
    try {
      const user: CognitoUser = await Auth.currentAuthenticatedUser();

      this._eventBus.Dispatch(Topics.IDN_AUTHENTICATION, new AuthenticationEvent(user, this.getIsAutenticated(user), ""))

    } catch (error) {
      this._eventBus.Dispatch(Topics.IDN_AUTHENTICATION, new AuthenticationEvent(null, false, ""))
    }

    this._logger.debug('Initializing Amplify Auth [DONE]');
  }

}