import { Logger } from "aws-amplify";
import { ILogger } from "../idn-media-framework-abstraction/ILogger";
import { LoggingLevels } from "../idn-media-framework-abstraction/LoggingLevels";

export class LoggerUsingAmplifyLogger implements ILogger {

  private _amplifyLogger: Logger;

  constructor(){
    this._amplifyLogger = new Logger('APPLICATION');
  }
  log(...msg: any[]): void {
    this._amplifyLogger.log(msg);
  }
  info(...msg: any[]): void {
    this._amplifyLogger.info(msg);
  }
  warn(...msg: any[]): void {
    this._amplifyLogger.warn(msg);
  }
  error(...msg: any[]): void {
    this._amplifyLogger.error(msg);
  }
  debug(...msg: any[]): void {
    this._amplifyLogger.debug(msg);
  }
  verbose(...msg: any[]): void {
    this._amplifyLogger.verbose(msg);
  }

  public AssignLogger(logger: Logger){
    this._amplifyLogger = logger;
  }

  Create(loggerName: string, level: LoggingLevels): ILogger {
    const logger = new Logger(loggerName, level);

    const instance = new LoggerUsingAmplifyLogger();

    instance.AssignLogger(logger);

    return instance;
  }
  
}