import { container, DependencyContainer, FactoryProvider, Lifecycle } from 'tsyringe';
import { constructor } from 'tsyringe/dist/typings/types';
import { IGenericDIContainer } from '../idn-media-framework-abstraction/IGenericDIContainer';
import { GenericContainerUsingDependencyContainer } from './GenericContainerUsingDependencyContainer';


export class DIContainer {
  static Resolve<T>(serviceName: string): T {
    return container.resolve(serviceName);
  }

  static RegisterAsService(serviceName: string, theClass: any){
    container.registerSingleton(serviceName, theClass);
  }

  static RegisterAsValue(valueName: string, value: any){
    container.registerInstance(valueName, value);

    
  }
  
  static RegisterWithFactory<T>(serviceName: string, factory: (container: IGenericDIContainer) => T){

    const provider: FactoryProvider<T> = {
      useFactory(dependencyContainer): T {
        return factory(new GenericContainerUsingDependencyContainer(dependencyContainer));
      },
    }

    container.register(serviceName, provider)
  }
}