import 'reflect-metadata';
// import '../styles/globals.css'
import type { AppProps } from 'next/app'
import Head from 'next/head';
// import { ChakraProvider } from '@chakra-ui/react'
import { useEffect } from 'react'
import { CompositionRoot } from '../src/CompositionRoot'
import '../styles/globals.css'
import { I18n } from '@aws-amplify/core';
import { customErrorMessages } from '@/constants/customErrorMessages';
import { ChakraProvider } from '@chakra-ui/react';
import theme from '@/styles/theme';


function MyApp({ Component, pageProps }: AppProps) {

  // useEffect(()=> {
  //   CompositionRoot.InitializeApplication();
  // }, [])

  const isCrawlNotAllowed = process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT !== 'production';
  let isInitialized = false;

  const init = async () => {
    if(isInitialized) return;
    await CompositionRoot.InitializeApplication();
  }

  useEffect(()=> {
    init();    
    isInitialized = true;    
  }, []);
  
  I18n.setLanguage('id');
  I18n.putVocabulariesForLanguage('id', customErrorMessages);

  // Hide console.log on production
  if (process.env.NODE_ENV === 'production') console.log = function () {};

  return (
    <>
      <Head>
        {isCrawlNotAllowed && <meta name="robots" content="noindex,nofollow" />}
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <title>IDN Account</title>
        <link rel="shortcut icon" href="/favicon.svg" />
        <script async src="/scripts/browser-validation.js" />
      </Head>
      {/* <Layout> */}
      <ChakraProvider theme={theme}>
        <Component {...pageProps} />
      </ChakraProvider>
      {/* </Layout> */}
    </>
  )
}

export default MyApp
