import { Hub } from 'aws-amplify';
import { HubCallback } from '@aws-amplify/core';
import { IEventBusSubcription } from "../idn-media-framework-abstraction/IEventBusSubcription";
import { ILogger } from '../idn-media-framework-abstraction/ILogger';


export class EventBusSubscriptionUsingAmplifyHub<T> implements IEventBusSubcription {
  constructor(private _topicName: string, private _handler: HubCallback, private _logger: ILogger) {
  }
  async Unsubscribe(): Promise<void> {
    Hub.remove(this._topicName, this._handler);
  }

}
